<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2023-12-08 15:43:57
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-08-15 14:04:34
 * @FilePath: /lcwpcvue/src/views/releaseJob.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="release-job-view">
    <div class="top-zk-div"></div>
    <div class="job-rz-view">
      <div class="left-view">
        <img class="vip-img" src="@/assets/releasejob_vip_img.png" alt="" />
        <div class="rz-txt">
          完成认证
          <label>获取猎宠网专属招聘权益</label>
        </div>
      </div>
      <div class="right-view">
        <div class="rz-gg-item">
          <img src="@/assets/releasejob_rz1_img.png" alt="" />
          <span>海量人才库</span>
        </div>
        <div class="rz-gg-item">
          <img src="@/assets/releasejob_rz3_img.png" alt="" />
          <span>免费发布职位</span>
        </div>
        <div class="rz-gg-item">
          <img src="@/assets/releasejob_rz2_img.png" alt="" />
          <span>免费直聊名额</span>
        </div>
      </div>
    </div>

    <div class="add-job-view">
      <div class="top-nav-bar-view">
        <div class="nav-bar">
          <div
            class="bar-item"
            @click="changeJobType(0)"
            :class="navBarId == 0 ? 'nav-sel' : ''"
          >
            发布全职
          </div>
          <div
            class="bar-item"
            @click="changeJobType(1)"
            :class="navBarId == 1 ? 'nav-sel' : ''"
          >
            发布兼职
          </div>
        </div>
      </div>

      <div class="job-inf-view" v-show="navBarId == 0">
        <el-form
          label-position="left"
          ref="fullJobRule-ref"
          size="medium"
          :rules="rules"
          :model="editJobObj"
        >
          <div class="releasejob-edi-sl-view">
            <div class="release-ms-title">
              <div class="left-xian"></div>
              <div class="title">基本信息</div>
            </div>

            <div class="edi-one-item">
              <el-form-item label="我要招聘" prop="">
                <div class="items">
<!--                  <div class="title">我要招聘</div>-->
                  <el-input
                      placeholder="请填写招聘职位"
                      clearable
                      v-model="editJobObj.name"
                  >
                  </el-input>
                </div>
              </el-form-item>
            </div>

            <div class="edi-one-item">
              <el-form-item label="职位类别" prop="">
                <div v-if="!isEdit" class="items" @click="isyxzw = true">
                  <!--                  <div class="title">职位类别</div>-->
                  <el-input
                    readonly
                    placeholder="请选择职位类别"
                    clearable
                    suffix-icon="el-icon-arrow-right"
                    v-model="editJobObj.job1_son_name"
                  >
                  </el-input>
                  <div class="zd-ingp-view"></div>
                </div>
                <div v-if="isEdit" class="items" @click="showTost">
                  <!--                  <div class="title">职位类别</div>-->
                  <el-input
                    readonly
                    clearable
                    suffix-icon="el-icon-arrow-right"
                    v-model="editJobObj.job1_son_name"
                    style="color: #ccc"
                  >
                  </el-input>
                  <div class="zd-ingp-view"></div>
                </div>
              </el-form-item>
            </div>

            <div class="edi-lin-item">
              <el-form-item label="经验要求" prop="exp">
                <div class="items">
                  <!--                  <div class="title"><span>经验要求</span></div>-->
                  <el-select
                    v-model="editJobObj.exp"
                    placeholder="请选择"
                    @change="expChose"
                  >
                    <el-option
                      v-for="item in expChoseArr"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </div>
              </el-form-item>
              <el-form-item label="学历要求" prop="edu">
                <div class="items">
                  <!--                  <div class="title"><span>学历要求</span></div>-->
                  <el-select
                    v-model="editJobObj.edu"
                    placeholder="请选择"
                    @change="eduChose"
                  >
                    <el-option
                      v-for="item in eduChoseArr"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </div>
              </el-form-item>
            </div>

            <div class="edi-lin-item add-job-salary">
              <!--              <el-form-item label="综合薪资（最低）" prop="minsalary">-->
              <el-form-item>
                <div class="items" style="width: 400px">
                  <div class="title">
                    <span v-show="!isNegotiable" style="color: #f56c6c"
                      >*&nbsp;</span
                    ><span>综合薪资（最低）</span>
                  </div>
                  <el-select
                    v-model="editJobObj.minsalary"
                    placeholder="最低薪资标准"
                    @visible-change="getMinWageList"
                    @change="minWageChose"
                  >
                    <el-option
                      v-for="item in minWageChoseArr"
                      :key="item"
                      :label="item"
                      :value="item"
                    ></el-option>
                  </el-select>
                </div>
              </el-form-item>
              <!--              <el-form-item label="综合薪资（最高）" prop="maxsalary">-->
              <el-form-item>
                <div class="items" style="width: 400px">
                  <div class="title">
                    <span v-show="!isNegotiable" style="color: #f56c6c"
                      >*&nbsp;</span
                    ><span>综合薪资（最高）</span>
                  </div>
                  <el-select
                    v-model="editJobObj.maxsalary"
                    placeholder="最高薪资标准"
                    @visible-change="getMaxWageList"
                    @change="maxWageChose"
                  >
                    <el-option
                      v-for="item in maxWageChoseArr"
                      :key="item"
                      :label="item"
                      :value="item"
                    ></el-option>
                  </el-select>
                </div>
              </el-form-item>

              <el-checkbox
                label="面议"
                name="type"
                v-model="isNegotiable"
              ></el-checkbox>
            </div>

            <div class="edi-one-item">
              <el-form-item label="职位描述" prop="description">
                <div class="items">
                  <div class="title" style="position: relative">
                    <!--                    职位描述-->
                    <span
                      style="
                        position: absolute;
                        left: 70px;
                        top: -14px;
                        border: #fe6002 1px solid;
                        padding: 0 3px;
                        line-height: 16px;
                      "
                      v-show="editJobObj.name != ''"
                      @click="getJobDespMod"
                      >换一个</span
                    >
                  </div>

                  <div class="textarea-view">
                    <el-input
                      type="textarea"
                      :rows="8"
                      :maxlength="500"
                      v-model="editJobObj.description"
                      placeholder="您的职位描述例如：
1. 岗位职责
2.任职要求
3.亮点优势
……"
                    ></el-input>
                    <div class="textarea-bot-view">
                      <span class="tt1"
                        >请勿填写<label>具体联系方式，</label>违反广告法/劳动法或包含其实词语的相关内容</span
                      >
                      <span class="tt2"
                        >{{ editJobObj.description.length }}/500</span
                      >
                    </div>
                  </div>
                </div>
              </el-form-item>
            </div>
            <el-divider></el-divider>
            <div class="release-ms-title">
              <div class="left-xian"></div>
              <div class="title">详细信息</div>
            </div>

            <div class="edi-lin-item">
              <el-form-item label="职位技能标签" prop="skill">
                <div class="items" @click="getJobsSkillsList">
<!--                  <div class="title">
                    <span v-show="!isNegotiable" style="color: #f56c6c"
                      >*&nbsp;</span
                    >
                    <span>职位技能标签</span>
                  </div>-->
                  <el-input
                    readonly
                    placeholder="请选择职位技能标签"
                    clearable
                    suffix-icon="el-icon-arrow-right"
                    v-model="editJobObj.skill"
                  >
                  </el-input>
                  <div class="zd-ingp-view"></div>
                </div>
              </el-form-item>
              <el-form-item label="福利待遇" prop="job_wf">
                <div class="items" @click="getJobsWeafList">
                  <el-input
                    readonly
                    placeholder="请选择福利待遇"
                    clearable
                    suffix-icon="el-icon-arrow-right"
                    v-model="editJobObj.job_wf"
                  >
                  </el-input>
                  <div class="zd-ingp-view"></div>
                </div>
              </el-form-item>
            </div>

            <div class="edi-lin-item">
              <el-form-item label="">
                <div class="items">
                  <div class="title"><span>工作时间</span></div>
                  <el-time-select
                    placeholder="请选择上班时间"
                    v-model="startWorkTime"
                    :picker-options="{
                      start: '00:00',
                      step: '00:30',
                      end: '23:30',
                    }"
                  >
                  </el-time-select>
                </div>
              </el-form-item>
              <el-form-item label="">
                <div class="items">
                  <div class="title">
                    <span style="opacity: 0">工作时间</span>
                  </div>
                  <el-time-select
                    placeholder="请选择下班时间"
                    v-model="endWorkTime"
                    :picker-options="{
                      start: '00:00',
                      step: '00:30',
                      end: '23:30',
                    }"
                  >
                  </el-time-select>
                </div>
              </el-form-item>
            </div>

            <div class="choseview">
              <el-form-item prop="">
                <div class="tt">工作班次</div>
                <div class="choseview-input-view-s">
                  <span
                    class="ch-item"
                    @click="changeJobTime('0')"
                    :class="editJobObj.work_shift === '0' ? 'sl-sex' : ''"
                    >常日班</span
                  >
                  <span
                    class="ch-item"
                    @click="changeJobTime('1')"
                    :class="editJobObj.work_shift === '1' ? 'sl-sex' : ''"
                    >白夜班</span
                  >
                  <span
                    class="ch-item"
                    @click="changeJobTime('2')"
                    :class="editJobObj.work_shift === '2' ? 'sl-sex' : ''"
                    >三班倒</span
                  >
                </div>
              </el-form-item>
            </div>

            <div class="edi-lin-item">
              <el-form-item label="">
                <div class="items">
                  <div class="title"><span>休息时间（选填）</span></div>
                  <el-select
                    v-model="editJobObj.rest_day"
                    placeholder="请选择休息时间"
                    @change="slpTimeChose"
                  >
                    <el-option
                      v-for="item in slpTimeChoseArr"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </div>
              </el-form-item>
              <el-form-item label="">
                <div class="items">
                  <div class="title"><span>加班情况（选填）</span></div>
                  <el-select
                    v-model="editJobObj.overtime_state"
                    placeholder="请选择加班情况"
                    @change="jbTimeChose"
                  >
                    <el-option
                      v-for="item in jbTimeChoseArr"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </div>
              </el-form-item>
            </div>

            <div class="edi-one-item">
              <el-form-item label="工作地点" prop="address">
                <div class="items" @click="addressDialogShow = true">
                  <!--                  <div class="title">工作地点</div>-->
                  <el-input
                    placeholder="请选择工作地点"
                    clearable
                    suffix-icon="el-icon-arrow-right"
                    v-model="editJobObj.address"
                  >
                  </el-input>
                  <div class="zd-ingp-view"></div>
                </div>
              </el-form-item>
            </div>

            <div class="bott-save-btn">
              <el-form-item label="">
                <el-button type="primary" @click="addFullJob"
                  >立即发布</el-button
                >
              </el-form-item>
            </div>
          </div>
        </el-form>
      </div>

      <div class="job-inf-view" v-if="navBarId == 1">
        <el-form label-position="left" ref="" size="medium">
          <div class="releasejob-edi-sl-view">
            <div class="release-ms-title">
              <div class="left-xian"></div>
              <div class="title">基本信息</div>
            </div>

            <div class="edi-one-item">
              <el-form-item label="">
                <div class="items">
                  <div class="title">我要招聘</div>
                  <el-input
                    placeholder="请填写招聘职位"
                    clearable
                    v-model="partJobObj.name"
                  >
                  </el-input>
                </div>
              </el-form-item>
            </div>

            <div class="edi-one-item">
              <el-form-item label="">
                <div class="items" @click="isyxzw = true">
                  <div class="title">职位类别</div>
                  <el-input
                    placeholder="请选择职位类别"
                    clearable
                    suffix-icon="el-icon-arrow-right"
                    v-model="partJobTypeName"
                  >
                  </el-input>
                  <div class="zd-ingp-view"></div>
                </div>
              </el-form-item>
            </div>

            <div class="edi-one-item">
              <el-form-item label="">
                <div class="items">
                  <div class="title">招聘人数</div>
                  <el-input
                    placeholder="请填写招聘人数"
                    clearable
                    v-model="partJobObj.number"
                  >
                  </el-input>
                </div>
              </el-form-item>
            </div>

            <el-divider></el-divider>

            <div class="release-ms-title">
              <div class="left-xian"></div>
              <div class="title">详细信息</div>
            </div>

            <div class="edi-lin-item">
              <el-form-item label="">
                <div class="items" @click="partJobTimeDialogShow = true">
                  <div class="title"><span>兼职时间</span></div>
                  <el-input
                    readonly
                    placeholder="请选择"
                    clearable
                    suffix-icon="el-icon-arrow-right"
                    v-model="timeShowText"
                  >
                  </el-input>
                  <div class="zd-ingp-view"></div>
                </div>
              </el-form-item>
              <el-form-item label="">
                <div class="items">
                  <div class="title"><span>兼职薪资</span></div>
                  <el-input
                    placeholder="请输入数字：XXX元"
                    clearable
                    v-model="partJobObj.salary"
                  >
                  </el-input>
                </div>
              </el-form-item>
            </div>

            <div class="edi-lin-item">
              <el-form-item label="">
                <div class="items">
                  <div class="title"><span>薪资类型</span></div>
                  <el-select
                    v-model="partJobObj.salary_type"
                    placeholder="最低薪资标准"
                  >
                    <el-option
                      v-for="item in partSalaryType"
                      :key="item.k"
                      :label="item.v"
                      :value="item.k"
                    ></el-option>
                  </el-select>
                </div>
              </el-form-item>
              <el-form-item label="">
                <div class="items">
                  <div class="title"><span>结算周期</span></div>
                  <el-select
                    v-model="partJobObj.billing_cycle"
                    placeholder="最高薪资标准"
                  >
                    <el-option
                      v-for="item in partSalarySyc"
                      :key="item.k"
                      :label="item.v"
                      :value="item.k"
                    ></el-option>
                  </el-select>
                </div>
              </el-form-item>
            </div>
            <el-divider></el-divider>

            <div class="edi-one-item">
              <el-form-item label="">
                <div class="items">
                  <div class="title">兼职内容</div>

                  <div class="textarea-view">
                    <el-input
                      type="textarea"
                      :rows="8"
                      :maxlength="500"
                      v-model="partJobObj.content"
                      placeholder="您的职位描述例如：
1. 岗位职责
2.任职要求
3.亮点优势
……"
                    ></el-input>
                    <div class="textarea-bot-view">
                      <span class="tt1"
                        >请勿填写<label>具体联系方式，</label>违反广告法/劳动法或包含其实词语的相关内容</span
                      >
                      <span class="tt2">0/500</span>
                    </div>
                  </div>
                </div>
              </el-form-item>
            </div>
            <el-divider></el-divider>

            <div class="edi-one-item">
              <el-form-item label="">
                <div class="items" @click="addressDialogShow = true">
                  <div class="title">工作地点</div>
                  <el-input
                    readonly
                    placeholder="请选择工作地点"
                    clearable
                    suffix-icon="el-icon-arrow-right"
                    v-model="partJobObj.address"
                  >
                  </el-input>
                  <div class="zd-ingp-view"></div>
                </div>
              </el-form-item>
            </div>

            <div class="edi-lin-item">
              <el-form-item label="">
                <div class="items">
                  <div class="title"><span>联系人</span></div>
                  <el-input
                    placeholder="请填写联系人"
                    clearable
                    v-model="partJobObj.linkman"
                  >
                  </el-input>
                </div>
              </el-form-item>
              <el-form-item label="">
                <div class="items">
                  <div class="title"><span>联系电话</span></div>
                  <el-input
                    placeholder="请填写联系电话"
                    clearable
                    v-model="partJobObj.linktel"
                  >
                  </el-input>
                </div>
              </el-form-item>
            </div>

            <div class="bott-save-btn">
              <el-form-item label="">
                <el-button type="primary" @click="addFullJob"
                  >立即发布</el-button
                >
              </el-form-item>
            </div>
          </div>
        </el-form>
      </div>
    </div>

    <choseonezw
      :isOneJob="true"
      :dialogVisible1="isyxzw"
      @closeDilog1="closeDilog1"
      @saveJobArr="saveJobArr"
    ></choseonezw>
    <choseaddress
      :addressDialogShow="addressDialogShow"
      @submitAddress="submitAddress"
      @closeAddresPop="closeAddresPop"
    ></choseaddress>
    <chosepartjobtime
      :partJobDialogShow="partJobTimeDialogShow"
      @partJobTimeArr="partJobTimeArr"
      @closeDateChoose="closeDateChoose"
    ></chosepartjobtime>

    <el-dialog
      title="技能标签"
      :visible.sync="skillsDialogVisible"
      width="700px"
    >
      <div class="jn-dialog">
        <div class="tt2">拥有技能（{{ choseSkillArr.length }}/6）</div>
        <div class="sl-bq-btn">
          <label v-for="(item, index) in choseSkillArr" :key="index">
            <div class="sel-jn-tag">
              <span>{{ item }}</span
              ><i
                class="el-icon-close"
                @click="deleChoseSkill(item, index)"
              ></i>
            </div>
          </label>
          <!-- <div class="addbq-bt" @click="addUserJnTag">+ 添加</div> -->
        </div>
        <div class="tt2">选择标签：</div>
        <div class="hot-bq-view">
          <label v-for="(item, index) in skillList" :key="index">
            <div class="hotbq-item" @click="choseSkill(item, index)">
              {{ item }}
            </div>
          </label>
          <div class="add-other" @click="addCustomTag(1)">
            <i class="el-icon-plus"></i> 自定义标签
          </div>
        </div>
        <div class="botm-btn-view">
          <el-button type="primary" @click="saveJobSkill">确定</el-button>
        </div>
      </div>
    </el-dialog>

    <el-dialog
      title="福利待遇"
      :visible.sync="jobWeafDialogVisible"
      width="700px"
    >
      <div class="jn-dialog">
        <div class="tt2">职位福利（{{ choseWeafArr.length }}/6）</div>
        <div class="sl-bq-btn">
          <label v-for="(item, index) in choseWeafArr" :key="index">
            <div class="sel-jn-tag">
              <span>{{ item }}</span
              ><i class="el-icon-close" @click="deleJobWeaf(item, index)"></i>
            </div>
          </label>
          <!-- <div class="addbq-bt" @click="addUserJnTag">+ 添加</div> -->
        </div>
        <div class="tt2">选择福利：</div>
        <div class="hot-bq-view">
          <label v-for="(item, index) in weafList" :key="index">
            <div class="hotbq-item" @click="choseJobWeaf(item, index)">
              {{ item }}
            </div>
          </label>
          <div class="add-other" @click="addCustomTag(2)">
            <i class="el-icon-plus"></i> 自定义福利
          </div>
        </div>
        <div class="botm-btn-view">
          <el-button type="primary" @click="saveJobWeaf">确定</el-button>
        </div>
      </div>
    </el-dialog>
    <PayJobDia
        :isopen="isOpenPayJob"
        :params="payJobParams"
        @payJobChang="payJobChang"
    ></PayJobDia>
  </div>
</template>

<script>
import {
  getReleaseJobCalss,
  addFullJob,
  editFullJob,
  testingJobStatus,
  addPartJob,
  updatePartJob,
  getJobsSkillsList,
  getJobsWeafList,
  getJobNum,
} from "@/api/compe/jobsApi.js";
import { fistChatToMsg } from "@/api/commen/chatApi.js";
import { getJobDetails } from "@/api/indiviuser/jobInfoPage";
import choseonezw from "@/components/choseZw.vue";
import choseaddress from "@/components/choseAddressDialog.vue";
import chosepartjobtime from "@/components/comUser/chosePartJobTime.vue";
import PayJobDia from "@/components/jobClass/payJob.vue";

export default {
  components: {
    PayJobDia,
    choseonezw,
    choseaddress,
    chosepartjobtime,
  },
  data() {
    return {
      navBarId: 0,
      isyxzw: false,
      expChoseArr: [],
      eduChoseArr: [],
      minWageChoseArr: [],
      maxWageChoseArr: [],
      addressDialogShow: false,
      partJobTimeDialogShow: false,
      slpTimeChoseArr: [
        {
          id: "2",
          name: "双休",
        },
        {
          id: "1",
          name: "单休",
        },
        {
          id: "3",
          name: "排班轮休",
        },
      ],
      jbTimeChoseArr: [
        {
          id: "1",
          name: "不加班",
        },
        {
          id: "2",
          name: "偶尔加班",
        },
        {
          id: "3",
          name: "弹性工作",
        },
      ],
      editJobObj: {
        id: "",
        name: "",
        job1_son: "",
        job1_son_name:"",
        job1: "",
        exp: "",
        edu: "",
        description: "",
        provinceid: "",
        cityid: "",
        three_cityid: "",
        x: "",
        y: "",
        minsalary: "",
        maxsalary: "",
        address: "",
        release_type: "normal",
        rating_id: "",
        addr_id: "",
        work_time: "",
        overtime_state: "",
        rest_day: "",
        work_shift: "",
        com_alias: "",
        salary_type: "",
        skill: "",
        job_wf: "",
      },
      partJobObj: {
        id: "",
        name: "",
        type: "",
        number: "",
        worktime: "",
        sex: 3,
        salary: "",
        salary_type: "",
        billing_cycle: "",
        provinceid: "",
        cityid: "",
        three_cityid: "",
        x: "",
        y: "",
        address: "",
        content: "",
        linkman: "",
        linktel: "",
        addr_id: "",
      },
      jobInf: [],
      partJobTypeName: "",
      startWorkTime: "",
      endWorkTime: "",
      timeShowText: "",
      partSalaryType: [
        { k: "19", v: "元/次" },
        { k: "17", v: "元/月" },
        { k: "16", v: "元/周" },
        { k: "15", v: "元/天" },
      ],
      partSalarySyc: [
        { k: "20", v: "日结" },
        { k: "21", v: "周结" },
        { k: "22", v: "月结" },
      ],
      isEdit: false,
      rules: {
        name: [{ required: true, message: "请选择职位类别", trigger: "blur" }],
        exp: [{ required: true, message: "请选择经验要求", trigger: "change" }],
        edu: [{ required: true, message: "请选择学历要求", trigger: "change" }],
        /*        minsalary: [
          { required: true, message: "请选择最低薪资标准", trigger: "blur" },
        ],
        maxsalary: [
          { required: true, message: "请选择最高薪资标准", trigger: "blur" },
        ],*/
        description: [
          { required: true, message: "请填写职位描述", trigger: "blur" },
        ],
        address: [
          { required: true, message: "请选择工作地点", trigger: "blur" },
        ],
        skill: [{ required: true, message: "请选择技能标签", trigger: "change" }],
        job_wf: [{ required: true, message: "请选择技能标签", trigger: "change" }],
      },
      isHaveJobRep: 1,
      isNegotiable: false,
      skillsDialogVisible: false,
      skillList: [],
      choseSkillArr: [],
      jobWeafDialogVisible: false,
      weafList: [],
      choseWeafArr: [],
      // payJobParams:{job_id:9999,job_type:8888,deadline:30,price:208,chat_num:30},
      payJobParams:{},
      isOpenPayJob:false,
    };
  },
  created() {
    if (this.$route.query.id) {
      this.editJobObj.id = this.$route.query.id;
      this.navBarId = this.$route.query.ispart;
      this.getJobDetails(this.$route.query.id);
      this.isEdit = true;
    }
    // this.$store.dispatch("user/setInfo").then(() => {
    //   this.userInf = this.$store.state.user.userinf;
    //   this.avatar = this.$store.state.user.avatar;
    // });

    if (!this.isEdit) {
      getJobNum()
        .then((result) => {
          if (result.data.job_num === "0") {
            this.$confirm(
              "您发布职位权益已经用完，您可以升级会员获得更多权益！！",
              "提示",
              {
                confirmButtonText: "购买权益",
                cancelButtonText: "关闭",
                type: "warning",
              }
            )
              .then(() => {
                this.$router.push("/vippaypage");
              })
              .catch(() => {
                this.$router.go(-1);
              });
          }
        })
        .catch(() => {});
    }

    this.getReleaseJobCalss("job_exp");
    this.getReleaseJobCalss("job_edu");
  },
  methods: {
    showTost() {
      this.$message({
        message: "不支持修改职位类别，您可以去发布新职位",
        type: "warning",
      });
    },
    getJobDetails(id) {
      getJobDetails({
        job_id: id,
        is_part: this.navBarId,
      })
        .then((result) => {
          console.log("职位详情", result);
          var jobinf = result.data.job_info;
          this.jobInf = result.data.job_info;
          if (this.navBarId == 0) {
            this.editJobObj = {
              id: jobinf.id,
              name: jobinf.name,
              job1_son: jobinf.job1_son,
              job1_son_name: jobinf.job1_son_name,
              job1: jobinf.job1,
              exp: jobinf.exp,
              edu: jobinf.edu,
              description: jobinf.description,
              provinceid: jobinf.provinceid,
              cityid: jobinf.cityid,
              three_cityid: jobinf.three_cityid,
              x: jobinf.x,
              y: jobinf.y,
              minsalary: jobinf.minsalary === "0" ? "" : jobinf.minsalary,
              maxsalary: jobinf.maxsalary === "0" ? "" : jobinf.maxsalary,
              address: jobinf.address,
              rating_id: 1,
              release_type: "normal",
              addr_id: jobinf.addr_id,
              work_time: jobinf.work_time,
              overtime_state: jobinf.overtime_state,
              rest_day: jobinf.rest_day,
              work_shift: jobinf.work_shift,
              com_alias: jobinf.alias,
              job_wf: jobinf.welfares,
              skill: '',
            };
            if (jobinf.skill.length > 0){
              this.editJobObj.skill = jobinf.skill.join();
            }
            this.choseWeafArr = jobinf.welfare;
            this.choseSkillArr = jobinf.skill;
            if (jobinf.work_time) {
              let timeArr = jobinf.work_time.split("-");
              this.startWorkTime = timeArr[0];
              this.endWorkTime = timeArr[1];
              if (this.endWorkTime.length <= 4)
                this.endWorkTime = "0" + this.endWorkTime;
              if (this.startWorkTime.length <= 4)
                this.startWorkTime = "0" + this.startWorkTime;
            }
            this.isNegotiable = jobinf.salary_type === "1";
          } else {
            this.partJobObj = {
              name: jobinf.name,
              type: jobinf.job_class,
              number: jobinf.number,
              worktime: jobinf.worktime,
              sex: jobinf.sex,
              salary: jobinf.salary,
              salary_type: jobinf.salary_type,
              billing_cycle: jobinf.billing_cycle,
              provinceid: jobinf.provinceid,
              cityid: jobinf.cityid,
              three_cityid: "",
              x: jobinf.x,
              y: jobinf.y,
              address: jobinf.address,
              content: jobinf.content,
              linkman: jobinf.linkman,
              linktel: jobinf.linktel,
              addr_id: jobinf.addr_id,
              id: jobinf.id,
            };
            this.partJobTypeName = jobinf.name;
            if (jobinf.worktime) {
              this.timeShowText = "已选择";
            }
          }
        })
        .catch(() => {});
    },

    closeDateChoose() {
      this.partJobTimeDialogShow = false;
    },
    closeAddresPop() {
      this.addressDialogShow = false;
    },
    changeJobType(e) {
      this.navBarId = e;
    },
    getReleaseJobCalss(name) {
      getReleaseJobCalss({ name })
        .then((result) => {
          console.log("获取经验要求", result);
          if (name == "job_exp") {
            this.expChoseArr = result.data.list.job_exp;
          } else if (name == "job_edu") {
            this.eduChoseArr = result.data.list.job_edu;
          }
        })
        .catch(() => {});
    },
    getMinWageList() {
      this.minWageChoseArr = [];
      for (let i = 1; i < 100; i++) {
        this.minWageChoseArr.push(i * 1000);
        // if (i < 5) {
        //   this.multiArray[1].push(i + 1 + "k");
        // }
      }
    },
    getMaxWageList() {
      this.isNegotiable = false;
      this.maxWageChoseArr = [];
      if (this.editJobObj.minsalary != "") {
        for (let i = this.editJobObj.minsalary / 1000; i < 100; i++) {
          this.maxWageChoseArr.push(parseInt(i + 1) * 1000);
        }
      } else {
        for (let i = 1; i < 100; i++) {
          this.maxWageChoseArr.push(i * 1000);
        }
      }
    },

    saveJobArr(e) {
      console.log("saveJobArr...", e);
      if (this.navBarId == 0) {
        this.choseSkillArr = [];
        this.editJobObj.skill = "";
        this.editJobObj.job1 = e.keyid;
        this.editJobObj.job1_son = e.id;
        this.editJobObj.job1_son_name = e.name;
      } else {
        this.partJobTypeName = e.name;
        this.partJobObj.type = e.id;
      }
      this.getJobDespMod();
      this.isyxzw = false;
    },

    /* 获取描述模版 */

    getJobDespMod() {
      fistChatToMsg({
        type: 1,
        job_id: this.editJobObj.job1_son,
      })
        .then((result) => {
          console.log("获取模版", result);
          if (result.data.content) {
            this.editJobObj.description = result.data.content;
          }
        })
        .catch(() => {});
    },

    changeJobTime(e) {
      console.log(e);
      this.editJobObj.work_shift = e;
    },
    minWageChose(e) {
      this.isNegotiable = false;
      console.log(e);
    },
    maxWageChose() {},
    expChose(e) {
      this.editJobObj.exp = e;
    },
    eduChose(e) {
      this.editJobObj.edu = e;
    },
    slpTimeChose(e) {
      console.log(e);
    },
    jbTimeChose(e) {
      console.log(e);
    },
    closeDilog1() {
      this.isyxzw = false;
    },

    /* 获取技能标签 */
    getJobsSkillsList() {
      if (this.editJobObj.job1_son == "") {
        this.$message({
          message: "请先选择职位类别",
          type: "warning",
        });
        return;
      }
      getJobsSkillsList({
        job1: this.editJobObj.job1,
        job1_son: this.editJobObj.job1_son,
      })
        .then((result) => {
          console.log("获取技能标签", result);
          this.skillList = result.data;
          this.skillsDialogVisible = true;
        })
        .catch(() => {});
    },

    choseSkill(item) {
      if (this.choseSkillArr.length >= 6) {
        this.$message({
          message: "最多只能选择6个",
          type: "warning",
        });
        return;
      }
      if (this.choseSkillArr.length > 0) {
        // this.sktags.forEach((it) => {
        if (this.choseSkillArr.indexOf(item) === -1) {
          this.choseSkillArr.push(item);
        } else {
          this.$message({
            message: "不能重复添加",
            type: "warning",
          });
          // uni.showToast({
          //   title: "已添加",
          //   icon: "none",
          // });
        }
        // });
      } else {
        this.choseSkillArr.push(item);
      }
    },

    saveJobSkill() {
      this.editJobObj.skill = String(this.choseSkillArr);
      this.skillsDialogVisible = false;
    },

    deleChoseSkill(item, index) {
      this.choseSkillArr.splice(index, 1);
    },

    /* 获取职位福利 */
    getJobsWeafList() {
      getJobsWeafList()
        .then((result) => {
          console.log("获取职位福利", result);
          var ls = [];
          result.data.job_wf.forEach((element) => {
            ls.push(element.name);
          });
          this.weafList = ls;
          this.jobWeafDialogVisible = true;
        })
        .catch(() => {});
    },

    choseJobWeaf(item) {
      if (this.choseWeafArr.length >= 6) {
        this.$message({
          message: "最多只能选择6个",
          type: "warning",
        });
        return;
      }
      if (this.choseWeafArr.length > 0) {
        // this.sktags.forEach((it) => {
        if (this.choseWeafArr.indexOf(item) === -1) {
          this.choseWeafArr.push(item);
        } else {
          this.$message({
            message: "不能重复添加",
            type: "warning",
          });
          // uni.showToast({
          //   title: "已添加",
          //   icon: "none",
          // });
        }
        // });
      } else {
        this.choseWeafArr.push(item);
      }
    },

    saveJobWeaf() {
      this.editJobObj.job_wf = String(this.choseWeafArr);
      this.jobWeafDialogVisible = false;
    },
    deleJobWeaf(item, index) {
      this.choseWeafArr.splice(index, 1);
    },
    addCustomTag(type) {
      this.$prompt("请输入标签", "自定义标签", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(({ value }) => {
          if (type == 1) {
            this.choseSkillArr.push(value);
          } else {
            this.choseWeafArr.push(value);
          }
        })
        .catch(() => {});
    },

    submitAddress(e) {
      console.log("选地址", e);
      if (!e.id) {
        this.$message.error("请选择地址");
        return;
      }
      if (this.navBarId == 0) {
        this.editJobObj.addr_id = e.id;
        this.editJobObj.x = e.x;
        this.editJobObj.y = e.y;
        this.editJobObj.provinceid = e.provinceid;
        this.editJobObj.cityid = e.cityid;
        this.editJobObj.three_cityid = e.three_cityid;
        this.editJobObj.address = e.address1;
      } else {
        this.partJobObj.addr_id = e.id;
        this.partJobObj.x = e.x;
        this.partJobObj.y = e.y;
        this.partJobObj.provinceid = e.provinceid;
        this.partJobObj.cityid = e.cityid;
        this.partJobObj.three_cityid = e.three_cityid;
        this.partJobObj.address = e.address1;
      }
      this.addressDialogShow = false;
    },
    payJobChang(){
      this.isOpenPayJob = false;
      this.$router.push('/jobmanage');
    },
    payJobDia(params,jobId,jobType,name){
      if (!params) {
        this.$router.go(-1);
        return;
      }
      params.job_id = jobId;
      params.job_type = jobType;
      params.job_name = name;
      this.payJobParams = params;
      this.isOpenPayJob = true;
    },
    /* 新建全职工作 */
    addFullJob() {
      console.log("新建工作", this.editJobObj);
      if (this.editJobObj.name === ""){
        this.editJobObj.name = this.editJobObj.job1_son_name;
      }
      if (this.navBarId == 0) {
        if (this.editJobObj.job1_son === "") {
          this.$message({
            message: "请选择职位类别",
            type: "warning",
          });
          return;
        }
        if (this.editJobObj.addr_id === "") {
          this.$message({
            message: "请选择工作地点",
            type: "warning",
          });
          return;
        }
        if (
          !this.isNegotiable &&
          this.editJobObj.minsalary >= this.editJobObj.maxsalary
        ) {
          this.editJobObj.minsalary = "";
          return this.$message.error("最低薪资必须大于最高薪资");
        }
        if (!this.isNegotiable) {
          if (!this.editJobObj.maxsalary || !this.editJobObj.minsalary) {
            return this.$message.error("请选择薪资或者勾选面议");
          }
        }
        this.$refs["fullJobRule-ref"].validate((valid) => {
          if (valid) {
            testingJobStatus({
              id: this.editJobObj.id,
              job_id: this.editJobObj.job1_son,
              provinceid: this.editJobObj.provinceid,
              cityid: this.editJobObj.cityid,
              three_cityid: this.editJobObj.three_cityid,
              maxsalary: this.editJobObj.minsalary,
              minsalary: this.editJobObj.maxsalary,
              addr_id: this.editJobObj.addr_id,
            })
              .then((result) => {
                console.log("判断职位", result);
                if (result.msg == "职位重复") {
                  this.$confirm(
                    "该地址您已发布有相同的职位，点击发布将会覆盖之前的职位信息，请确认是否继续发布？",
                    "提示",
                    {
                      confirmButtonText: "确认发布",
                      cancelButtonText: "取消",
                      type: "warning",
                    }
                  )
                    .then(() => {
                      if (this.startWorkTime && this.endWorkTime) {
                        if (this.startWorkTime.charAt(0) === "0") {
                          this.startWorkTime = this.startWorkTime.substring(1);
                        }
                        if (this.endWorkTime.charAt(0) === "0") {
                          this.endWorkTime = this.endWorkTime.substring(1);
                        }
                        this.editJobObj.work_time =
                          this.startWorkTime + "-" + this.endWorkTime;
                      } else {
                        this.editJobObj.work_time = "";
                      }
                      this.editJobObj.salary_type = this.isNegotiable ? 1 : 2;
                      this.editJobObj.id = result.data.job_id;
                      editFullJob(this.editJobObj)
                        .then((result) => {
                          if (result.code == 200) {
                            this.$message({
                              message: "修改成功！",
                              type: "success",
                              center: true,
                            });
                            this.payJobDia(result.data.isPay,this.editJobObj.id,this.editJobObj.job1_son);
                            // this.$router.push("/jobmanage?tabid=wait");
                          }
                        })
                        .catch(() => {});
                      /*                      if (result.data.state == 0) {
                        this.$router.push("/jobmanage?tabid=wait");
                      } else if (result.data.state == 1) {
                        if (result.data.status == 0) {
                          this.$router.push("/jobmanage?tabid=ing");
                        } else if (result.data.status == 1) {
                          this.$router.push("/jobmanage?tabid=down");
                        }
                      } else if (
                        result.data.state == 2 ||
                        result.data.state == 3
                      ) {
                        this.$router.push("/jobmanage?tabid=down");
                      }*/
                    })
                    .catch(() => {
                      /*                      this.editJobObj = {
                        id: "",
                        name: "",
                        job1_son: "",
                        exp: "",
                        edu: "",
                        description: "",
                        provinceid: "",
                        cityid: "",
                        three_cityid: "",
                        x: "",
                        y: "",
                        minsalary: "",
                        maxsalary: "",
                        address: "",
                        release_type: "normal",
                        rating_id: "",
                        addr_id: "",
                        work_time: "",
                        overtime_state: "",
                        rest_day: "",
                        work_shift: "",
                        com_alias: "",
                      };*/
                      this.editJobObj.id = "";
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                      // this.saveJob();
                    });
                }
                // else if (result.msg == "薪资低") {
                //   this.$confirm(result.msg, "提示", {
                //     confirmButtonText: "提升薪资",
                //     cancelButtonText: "任性发布",
                //     type: "warning",
                //   })
                //     .then(() => {})
                //     .catch(() => {
                //       this.saveJob();
                //     });
                // }
                else {
                  this.saveJob();
                }
              })
              .catch(() => {});
          } else {
            console.log(valid);
            console.log(this.editJobObj);
            return this.$message.error("请完善信息");
          }
        });
      } else {
        this.savePartJob();
      }
    },
    saveJob() {
      console.log(this.isEdit, "编辑", this.editJobObj);
      if (this.editJobObj.job1_son == "") {
        this.$message({
          message: "请选择职位类别",
          type: "warning",
        });
        return;
      }
      if (this.editJobObj.addr_id == "") {
        this.$message({
          message: "请选择工作地点",
          type: "warning",
        });
        return;
      }
      if (this.startWorkTime && this.endWorkTime) {
        if (this.startWorkTime.charAt(0) === "0") {
          this.startWorkTime = this.startWorkTime.substring(1);
        }
        if (this.endWorkTime.charAt(0) === "0") {
          this.endWorkTime = this.endWorkTime.substring(1);
        }
        this.editJobObj.work_time = this.startWorkTime + "-" + this.endWorkTime;
      } else {
        this.editJobObj.work_time = "";
      }
      this.editJobObj.salary_type = this.isNegotiable ? 1 : 2;
      if (this.isEdit) {
        editFullJob(this.editJobObj)
          .then((result) => {
            console.log("编辑工作", result);
            if (result.code == 200) {
              this.$message({
                message: "修改成功！",
                type: "success",
                center: true,
              });
              this.payJobDia(result.data.isPay,this.editJobObj.id,this.editJobObj.job1_son,this.editJobObj.name);
              // this.$router.go(-1);
            }
          })
          .catch(() => {});
      } else {
        /* 新建 */
        addFullJob(this.editJobObj)
          .then((result) => {
            console.log("新建工作", this.editJobObj, result);
            localStorage.setItem("zwdetails", this.editJobObj);
            if (result.code == 200) {
              this.$message({
                message: "发布成功！",
                type: "success",
                center: true,
              });
              this.payJobDia(result.data.isPay,result.data.job_id,this.editJobObj.job1_son,this.editJobObj.name);
              // this.$router.go(-1);
            }
          })
          .catch(() => {});
      }
    },

    savePartJob() {
      if (this.partJobObj.id) {
        updatePartJob(this.partJobObj).then((res) => {
          console.log(res);
        });
      } else {
        addPartJob(this.partJobObj)
          .then(() => {
            this.$message.success("发布成功");
            this.$router.go(-1);
          })
          .catch(() => {});
      }
    },
    partJobTimeArr(e) {
      console.log(String(e));
      if (e.length > 0) {
        this.timeShowText = "已选择";
        this.partJobObj.worktime = String(e);
      }

      this.partJobTimeDialogShow = false;
    },
  },
};
</script>

<style>
.release-job-view .el-dialog__wrapper .el-dialog {
  border-radius: 15px;
}
.release-job-view {
  /* padding: 0 345px; */
  display: flex;
  flex-direction: column;
  align-items: center;
}
.breadcrumb {
  margin-top: 24px;
}
.job-rz-view {
  width: 1231px;
  height: 60px;
  background: #fff5f0;
  border-radius: 10px;
  /* margin-top: 24px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.job-rz-view .left-view {
  display: flex;
  align-items: center;
  margin-left: 52px;
  position: relative;
}

.job-rz-view .left-view .vip-img {
  width: 44px;
  height: 34px;
}

.job-rz-view .left-view .rz-txt {
  font-size: 18px;

  font-weight: 600;
  color: #191919;
  position: relative;
  margin-left: -25px;
}
.job-rz-view .left-view .rz-txt label {
  color: #fe6002;
}

.job-rz-view .right-view {
  display: flex;
  align-items: center;
  margin-right: 49px;
}

.job-rz-view .right-view .rz-gg-item {
  display: flex;
  align-items: center;
  font-size: 14px;

  font-weight: 400;
  color: #757575;
  line-height: 16px;
  margin-left: 30px;
}
.job-rz-view .right-view .rz-gg-item img {
  width: 22px;
  height: 22px;
  margin-right: 8px;
}

.top-nav-bar-view {
  width: 1231px;
  height: 140px;
  background-color: #fff;
  margin-top: 40px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.top-nav-bar-view .nav-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1016px;
  height: 60px;
  background-color: #f5f6fa;
  border-radius: 40px;
}
.top-nav-bar-view .nav-bar .bar-item {
  width: 500px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;

  font-weight: 400;
  color: #191919;
  border-radius: 26px;
}
.top-nav-bar-view .nav-bar .nav-sel {
  background-color: #fff;
  color: #fe6002;
}
.job-inf-view {
  background-color: #f5f6fa;
  padding: 40px 18px;
}

.releasejob-edi-sl-view {
}

.releasejob-edi-sl-view .edi-lin-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.releasejob-edi-sl-view .edi-lin-item .items {
  width: 574px;
  /* margin-right: 78px; */
  position: relative;
}
.el-autocomplete {
  width: 100%;
}

.releasejob-edi-sl-view .edi-lin-item .items .title,
.releasejob-edi-sl-view label {
  font-size: 16px;

  font-weight: 400;
  color: #757575;
  line-height: 30px;
  margin-top: 24px;
}

.releasejob-edi-sl-view .edi-lin-item .sl-clik-view {
  display: flex;
}
.releasejob-edi-sl-view .edi-lin-item .sl-clik-view .cl-change-item {
  width: 574px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #e7e8eb;
  font-size: 16px;

  font-weight: 400;
  color: #757575;
}
.releasejob-edi-sl-view .edi-lin-item .items .el-input.el-input--suffix {
  width: 574px;
  /* height: 46px; */
}
.releasejob-edi-sl-view .edi-one-item .items .zd-ingp-view {
  width: 100%;
  height: 46px;
  /* border: 1px solid #000; */
  position: absolute;
  top: 30px;
}

.releasejob-edi-sl-view .edi-lin-item .items .zd-ingp-view {
  width: 100%;
  height: 46px;
  /* border: 1px solid #000; */
  position: absolute;
  top: 30px;
}
.releasejob-edi-sl-view .edi-lin-item .items .sl-group-view {
  display: flex;
  align-items: center;
}
.releasejob-edi-sl-view .release-ms-title {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.releasejob-edi-sl-view .release-ms-title .left-xian {
  width: 4px;
  height: 20px;
  background-color: #fe6002;
  border-radius: 3px;
}
.edi-one-item .title {
  font-size: 16px;

  font-weight: 400;
  color: #757575;
  line-height: 30px;
  margin-top: 24px;
}
.edi-one-item .title span {
  font-size: 13px;

  font-weight: 400;
  color: #fe6002;
  line-height: 30px;
  margin-top: 24px;
  cursor: pointer;
}
.releasejob-edi-sl-view .release-ms-title .title {
  font-size: 22px;

  font-weight: 600;
  color: #222222;
  margin-left: 10px;
}
.edi-one-item .textarea-view {
  margin-top: 0;
}
.edi-one-item .textarea-view .textarea-bot-view {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.edi-one-item .textarea-view .textarea-bot-view .tt1 {
  font-size: 16px;

  font-weight: 400;
  color: #b1b1b1;
}
.edi-one-item .textarea-view .textarea-bot-view .tt1 label {
  color: #f34b37;
}
.edi-one-item .textarea-view .textarea-bot-view .tt2 {
  font-size: 16px;

  font-weight: 400;
  color: #757575;
  line-height: 19px;
}

.release-job-view .choseview {
  position: relative;
  width: 100%;
}

.release-job-view .choseview .tt {
  font-size: 14px;
  color: #606266;
}

.release-job-view .choseview .choseview-input-view-s {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.release-job-view .choseview .choseview-input-view-s .ch-item {
  display: block;
  width: 350px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #e4e7ed;
  text-align: center;
  background-color: #fff;
  color: #b1b1b1;
  line-height: 40px;
}

/* .release-job-view .choseview .choseview-input-view .ch-item:first-child {
  margin-right: 58px;
} */

.release-job-view .choseview .choseview-input-view-s .sl-sex {
  background-color: #fff5f0;
  color: #fe6002;
  border: 1px solid #fe6002;
}

.release-job-view .bott-save-btn {
  width: 100%;
  text-align: center;
}

button.el-button.el-button--primary.el-button--medium {
  width: 400px;
  height: 52px;
  font-size: 18px;

  font-weight: 600;
  color: #ffffff;
}
.release-job-view .add-job-salary .items .el-select .el-input {
  width: 500px;
}

.release-job-view .jn-dialog {
  margin: 20px 30px;
  padding-bottom: 30px;
}

.release-job-view .jn-dialog .tt2 {
  font-size: 20px;
  font-weight: 600;
  color: #191919;
  line-height: 28px;
  margin-top: 30px;
}
.release-job-view .jn-dialog .sl-bq-btn {
  margin-top: 24px;
  /* display: flex;
  align-items: center; */
}

.release-job-view .jn-dialog .sl-bq-btn .addbq-bt {
  width: 86px;
  height: 36px;
  border: 1px solid #fe6002;
  font-size: 18px;

  font-weight: 400;
  color: #fe6002;
  line-height: 36px;
  border-radius: 2px;
  text-align: center;
  display: inline-block;
  margin-bottom: 10px;
}
.release-job-view .jn-dialog .sl-bq-btn .sel-jn-tag {
  display: flex;
  align-items: center;
  display: inline-block;
  font-size: 18px;

  font-weight: 400;
  color: #fffdfc;
  line-height: 24px;
  background-color: #ff8136;
  padding: 7px 8px;
  border-radius: 2px;
  margin-right: 16px;
  margin-bottom: 10px;
}
.release-job-view .jn-dialog .sl-bq-btn .sel-jn-tag span {
  margin-right: 10px;
}

.release-job-view .jn-dialog .hot-bq-view .hotbq-item {
  display: inline-block;
  background-color: #f5f6fa;
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 16px;

  font-weight: 400;
  color: #757575;
  line-height: 19px;
  margin-top: 20px;
  margin-right: 20px;
}

.release-job-view .jn-dialog .hot-bq-view .add-other {
  width: 120px;
  background-color: #f5f6fa;
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 400;
  color: #757575;
  line-height: 19px;
  margin-top: 20px;
  margin-right: 20px;
}

.release-job-view .jn-dialog .botm-btn-view {
  margin-top: 30px;
  width: 100%;
  text-align: right;
}

.release-job-view .jn-dialog .botm-btn-view .el-button {
  width: 300px;
}
</style>