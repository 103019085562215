<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2024-02-20 16:49:22
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-06-07 13:46:24
 * @FilePath: /lcwpcvue/src/components/comUser/registReleaseJob.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="business-releasejob-dialog">
    <div class="reg-release-job-view">
      <registhead></registhead>
      <steppro :stepnub="3"></steppro>
      <div class="add-job-view">
        <div class="top-nav-bar-view">
          <div class="nav-bar">
            <div
              class="bar-item"
              @click="changeJobType(1)"
              :class="navBarId == 1 ? 'nav-sel' : ''"
            >
              发布全职
            </div>
            <div
              class="bar-item"
              @click="changeJobType(2)"
              :class="navBarId == 2 ? 'nav-sel' : ''"
            >
              发布兼职
            </div>
          </div>
        </div>

        <div class="job-inf-view" v-show="navBarId == 1">
          <el-form
            label-position="left"
            ref="fullJob-ref"
            size="medium"
            :rules="fullrules"
            :model="editJobObj"
          >
            <div class="releasejob-edi-sl-view">
              <div class="edi-one-item">
                <el-form-item label="我要招聘" prop="name">
                  <div class="items">
                    <!--                    <div class="title">我要招聘</div>-->
                    <el-input
                      placeholder="请填写招聘职位"
                      clearable
                      v-model="editJobObj.name"
                    >
                    </el-input>
                  </div>
                </el-form-item>
              </div>

              <div class="edi-one-item">
                <el-form-item label="职位类别" prop="job1_son">
                  <div class="items" @click="isyxzw = true">
                    <!--                    <div class="title">职位类别L</div>-->
                    <el-input
                      readonly
                      placeholder="请选择职位类别"
                      clearable
                      suffix-icon="el-icon-arrow-right"
                      v-model="tmpName"
                    >
                    </el-input>
                    <div class="zd-ingp-view"></div>
                  </div>
                </el-form-item>
              </div>

              <!-- <el-divider></el-divider> -->

              <div class="edi-lin-item">
                <el-form-item label="经验要求" prop="exp">
                  <div class="items">
                    <!--                    <div class="title"><span>经验要求</span></div>-->
                    <el-select
                      v-model="editJobObj.exp"
                      placeholder="请选择"
                      @visible-change="getReleaseJobCalss('job_exp')"
                      @change="expChose"
                    >
                      <el-option
                        v-for="item in expChoseArr"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                      ></el-option>
                    </el-select>
                  </div>
                </el-form-item>
                <el-form-item label="学历要求" prop="edu">
                  <div class="items">
                    <!--                    <div class="title"><span>学历要求</span></div>-->
                    <el-select
                      v-model="editJobObj.edu"
                      placeholder="请选择"
                      @visible-change="getReleaseJobCalss('job_edu')"
                      @change="eduChose"
                    >
                      <el-option
                        v-for="item in eduChoseArr"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                      ></el-option>
                    </el-select>
                  </div>
                </el-form-item>
              </div>

              <div class="edi-lin-item regist-job-salary">
<!--                <el-form-item label="综合薪资" prop="minsalary">-->
                <el-form-item>
                  <div class="items" style="width: 300px">
                  <div class="title">
                    <span v-show="!isNegotiable" style="color: #f56c6c;">*&nbsp;</span>
                    <span>综合薪资</span>
                  </div>
                    <el-select
                      v-model="editJobObj.minsalary"
                      placeholder="最低薪资标准"
                      @visible-change="getMinWageList"
                      @change="minWageChose"
                    >
                      <el-option
                        v-for="item in minWageChoseArr"
                        :key="item"
                        :label="item"
                        :value="item"
                      ></el-option>
                    </el-select>
                  </div>
                </el-form-item>
<!--                <el-form-item label="综合薪资" prop="maxsalary">-->
                <el-form-item>
                  <div class="items" style="width: 300px">
                    <div class="title">
                      <span v-show="!isNegotiable" style="color: #f56c6c;">*&nbsp;</span>
                      <span>综合薪资</span>
                    </div>
                    <el-select
                      v-model="editJobObj.maxsalary"
                      placeholder="最高薪资标准"
                      @visible-change="getMaxWageList"
                      @change="maxWageChose"
                    >
                      <el-option
                        v-for="item in maxWageChoseArr"
                        :key="item"
                        :label="item"
                        :value="item"
                      ></el-option>
                    </el-select>
                  </div>
                </el-form-item>
                <el-checkbox  label="面议" name="type" v-model="isNegotiable"></el-checkbox>
              </div>

              <div class="edi-one-item">
                <el-form-item label="职位描述" prop="description">
                  <div class="items">
                    <div
                      class="title"
                      style="position: absolute; top: -5px; left: 85px"
                    >
                      <span
                        v-show="editJobObj.job1_son != ''"
                        @click="getJobDespMod"
                        >换一个</span
                      >
                    </div>
                    <div class="textarea-view">
                      <el-input
                        type="textarea"
                        :rows="8"
                        :maxlength="500"
                        v-model="editJobObj.description"
                        placeholder="您的职位描述例如：
1. 岗位职责
2.任职要求
3.亮点优势
……"
                      ></el-input>
                      <div class="textarea-bot-view">
                        <span class="tt1"
                          >请勿填写<label>具体联系方式，</label>违反广告法/劳动法或包含其实词语的相关内容</span
                        >
                        <span class="tt2">0/500</span>
                      </div>
                    </div>
                  </div>
                </el-form-item>
              </div>

              <div class="edi-lin-item">
                <el-form-item label="职位技能标签" prop="skill">
                  <div class="items" @click="getJobsSkillsList">
                    <el-input
                        readonly
                        placeholder="请选择职位技能标签"
                        clearable
                        suffix-icon="el-icon-arrow-right"
                        v-model="editJobObj.skill"
                    >
                    </el-input>
                    <div class="zd-ingp-view"></div>
                  </div>
                </el-form-item>
                <el-form-item label="福利待遇" prop="job_wf">
                  <div class="items" @click="getJobsWeafList">
                    <el-input
                        readonly
                        placeholder="请选择福利待遇"
                        clearable
                        suffix-icon="el-icon-arrow-right"
                        v-model="editJobObj.job_wf"
                    >
                    </el-input>
                    <div class="zd-ingp-view"></div>
                  </div>
                </el-form-item>
              </div>

              <div class="edi-lin-item">
                <el-form-item label="">
                  <div class="items">
                    <div class="title"><span>工作时间</span></div>
                    <el-time-select
                      placeholder="请选择上班时间"
                      v-model="startWorkTime"
                      :picker-options="{
                        start: '00:00',
                        step: '00:30',
                        end: '23:30',
                      }"
                    >
                    </el-time-select>
                  </div>
                </el-form-item>
                <el-form-item label="">
                  <div class="items">
                    <div class="title">
                      <span style="opacity: 0">工作时间</span>
                    </div>
                    <el-time-select
                      placeholder="请选择下班时间"
                      v-model="endWorkTime"
                      :picker-options="{
                        start: '00:00',
                        step: '00:30',
                        end: '23:30',
                      }"
                    >
                    </el-time-select>
                  </div>
                </el-form-item>
              </div>

              <div class="choseview">
                <el-form-item prop="">
                  <div class="tt">工作班次</div>
                  <div class="choseview-input-view">
                    <span
                      class="ch-item-s"
                      @click="changeJobTime('0')"
                      :class="editJobObj.work_shift === '0' ? 'sl-sex' : ''"
                      >常白班</span
                    >
                    <span
                      class="ch-item-s"
                      @click="changeJobTime('1')"
                      :class="editJobObj.work_shift === '1' ? 'sl-sex' : ''"
                      >白夜班</span
                    >
                    <span
                      class="ch-item-s"
                      @click="changeJobTime('2')"
                      :class="editJobObj.work_shift === '2' ? 'sl-sex' : ''"
                      >三班倒</span
                    >
                  </div>
                </el-form-item>
              </div>

              <div class="edi-lin-item">
                <el-form-item label="">
                  <div class="items">
                    <div class="title"><span>休息时间（选填）</span></div>
                    <el-select
                      v-model="editJobObj.rest_day"
                      placeholder="请选择休息时间"
                      @change="slpTimeChose"
                    >
                      <el-option
                        v-for="item in slpTimeChoseArr"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                      ></el-option>
                    </el-select>
                  </div>
                </el-form-item>
                <el-form-item label="">
                  <div class="items">
                    <div class="title"><span>加班情况（选填）</span></div>
                    <el-select
                      v-model="editJobObj.overtime_state"
                      placeholder="请选择加班情况"
                      @change="jbTimeChose"
                    >
                      <el-option
                        v-for="item in jbTimeChoseArr"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                      ></el-option>
                    </el-select>
                  </div>
                </el-form-item>
              </div>

              <div class="edi-one-item">
                <el-form-item label="工作地点" prop="address">
                  <div class="items" @click="addressDialogShow = true">
                    <!--                    <div class="title">工作地点</div>-->
                    <el-input
                      placeholder="请选择工作地点"
                      clearable
                      suffix-icon="el-icon-arrow-right"
                      v-model="editJobObj.address"
                    >
                    </el-input>
                    <div class="zd-ingp-view"></div>
                  </div>
                </el-form-item>
              </div>

              <div class="bott-save-btn">
                <el-form-item label="">
                  <el-button @click="goBackPage">上一步</el-button>
                  <el-button @click="toIndexPage">跳过</el-button>
                  <el-button type="primary" @click="addFullJob"
                    >下一步</el-button
                  >
                </el-form-item>
              </div>
            </div>
          </el-form>
        </div>

        <div class="job-inf-view" v-show="navBarId == 2">
          <el-form label-position="left" ref="" size="medium">
            <div class="releasejob-edi-sl-view">
              <div class="release-ms-title">
                <div class="left-xian"></div>
                <div class="title">基本信息</div>
              </div>

              <div class="edi-one-item">
                <el-form-item label="">
                  <div class="items">
                    <div class="title">我要招聘</div>
                    <el-input
                      placeholder="请填写招聘职位"
                      clearable
                      v-model="partJobObj.name"
                    >
                    </el-input>
                  </div>
                </el-form-item>
              </div>

              <div class="edi-one-item">
                <el-form-item label="">
                  <div class="items" @click="isyxzw = true">
                    <div class="title">职位类别</div>
                    <el-input
                      placeholder="请选择职位类别"
                      clearable
                      suffix-icon="el-icon-arrow-right"
                      v-model="partJobTypeName"
                    >
                    </el-input>
                    <div class="zd-ingp-view"></div>
                  </div>
                </el-form-item>
              </div>

              <div class="edi-one-item">
                <el-form-item label="">
                  <div class="items">
                    <div class="title">招聘人数</div>
                    <el-input
                      placeholder="请填写招聘人数"
                      clearable
                      v-model="partJobObj.number"
                    >
                    </el-input>
                  </div>
                </el-form-item>
              </div>

              <div class="release-ms-title">
                <div class="left-xian"></div>
                <div class="title">详细信息</div>
              </div>

              <div class="edi-lin-item">
                <el-form-item label="">
                  <div class="items" @click="partJobTimeDialogShow = true">
                    <div class="title"><span>兼职时间</span></div>
                    <el-input
                      placeholder="请选择"
                      clearable
                      suffix-icon="el-icon-arrow-right"
                      v-model="timeShowText"
                    >
                    </el-input>
                    <div class="zd-ingp-view"></div>
                  </div>
                </el-form-item>
                <el-form-item label="">
                  <div class="items">
                    <div class="title"><span>兼职薪资</span></div>
                    <el-input
                      placeholder="请输入数字：XXX元"
                      clearable
                      v-model="partJobObj.salary"
                    >
                    </el-input>
                  </div>
                </el-form-item>
              </div>

              <div class="edi-lin-item">
                <el-form-item label="">
                  <div class="items">
                    <div class="title"><span>薪资类型</span></div>
                    <el-select
                      v-model="partJobObj.salary_type"
                      placeholder="最低薪资标准"
                    >
                      <el-option
                        v-for="item in partSalaryType"
                        :key="item"
                        :label="item"
                        :value="item"
                      ></el-option>
                    </el-select>
                  </div>
                </el-form-item>
                <el-form-item label="">
                  <div class="items">
                    <div class="title"><span>结算周期</span></div>
                    <el-select
                      v-model="partJobObj.billing_cycle"
                      placeholder="最高薪资标准"
                    >
                      <el-option
                        v-for="item in partSalarySyc"
                        :key="item"
                        :label="item"
                        :value="item"
                      ></el-option>
                    </el-select>
                  </div>
                </el-form-item>
              </div>
              <div class="edi-one-item">
                <el-form-item label="">
                  <div class="items">
                    <div class="title">兼职内容</div>
                    <div class="textarea-view">
                      <el-input
                        type="textarea"
                        :rows="8"
                        :maxlength="500"
                        v-model="partJobObj.content"
                        placeholder="您的职位描述例如：
1. 岗位职责
2.任职要求
3.亮点优势
……"
                      ></el-input>
                      <div class="textarea-bot-view">
                        <span class="tt1"
                          >请勿填写<label>具体联系方式，</label>违反广告法/劳动法或包含其实词语的相关内容</span
                        >
                        <span class="tt2">0/500</span>
                      </div>
                    </div>
                  </div>
                </el-form-item>
              </div>

              <div class="edi-one-item">
                <el-form-item label="">
                  <div class="items" @click="addressDialogShow = true">
                    <div class="title">工作地点</div>
                    <el-input
                      placeholder="请选择工作地点"
                      clearable
                      suffix-icon="el-icon-arrow-right"
                      v-model="partJobObj.address"
                    >
                    </el-input>
                    <div class="zd-ingp-view"></div>
                  </div>
                </el-form-item>
              </div>

              <div class="edi-lin-item">
                <el-form-item label="">
                  <div class="items">
                    <div class="title"><span>联系人</span></div>
                    <el-input
                      placeholder="请填写联系人"
                      clearable
                      v-model="partJobObj.linkman"
                    >
                    </el-input>
                  </div>
                </el-form-item>
                <el-form-item label="">
                  <div class="items">
                    <div class="title"><span>联系电话</span></div>
                    <el-input
                      placeholder="请填写联系电话"
                      clearable
                      v-model="partJobObj.linktel"
                    >
                    </el-input>
                  </div>
                </el-form-item>
              </div>

              <div class="bott-save-btn">
                <el-form-item label="">
                  <el-button @click="goBackPage">上一步</el-button>
                  <el-button @click="toIndexPage">跳过</el-button>
                  <el-button type="primary" @click="addFullJob"
                    >下一步</el-button
                  >
                </el-form-item>
              </div>
            </div>
          </el-form>
        </div>
      </div>
      <el-dialog
          title="福利待遇"
          :visible.sync="jobWeafDialogVisible"
          width="700px"
      >
        <div class="jn-dialog">
          <div class="tt2">职位福利（{{ choseWeafArr.length }}/6）</div>
          <div class="sl-bq-btn">
            <label v-for="(item, index) in choseWeafArr" :key="index">
              <div class="sel-jn-tag">
              <span>{{ item }}</span
              ><i class="el-icon-close" @click="deleJobWeaf(item, index)"></i>
              </div>
            </label>
            <!-- <div class="addbq-bt" @click="addUserJnTag">+ 添加</div> -->
          </div>
          <div class="tt2">选择福利：</div>
          <div class="hot-bq-view">
            <label v-for="(item, index) in weafList" :key="index">
              <div class="hotbq-item" @click="choseJobWeaf(item, index)">
                {{ item }}
              </div>
            </label>
            <div class="add-other" @click="addCustomTag(2)">
              <i class="el-icon-plus"></i> 自定义福利
            </div>
          </div>
          <div class="botm-btn-view">
            <el-button type="primary" @click="saveJobWeaf">确定</el-button>
          </div>
        </div>
      </el-dialog>

      <el-dialog
          title="技能标签"
          :visible.sync="skillsDialogVisible"
          width="700px"
      >
        <div class="jn-dialog">
          <div class="tt2">拥有技能（{{ choseSkillArr.length }}/6）</div>
          <div class="sl-bq-btn">
            <label v-for="(item, index) in choseSkillArr" :key="index">
              <div class="sel-jn-tag">
              <span>{{ item }}</span
              ><i
                  class="el-icon-close"
                  @click="deleChoseSkill(item, index)"
              ></i>
              </div>
            </label>
          </div>
          <div class="tt2">选择标签：</div>
          <div class="hot-bq-view">
            <label v-for="(item, index) in skillList" :key="index">
              <div class="hotbq-item" @click="choseSkill(item, index)">
                {{ item }}
              </div>
            </label>
            <div class="add-other" @click="addCustomTag(1)">
              <i class="el-icon-plus"></i> 自定义标签
            </div>
          </div>
          <div class="botm-btn-view">
            <el-button type="primary" @click="saveJobSkill">确定</el-button>
          </div>
        </div>
      </el-dialog>

      <choseonezw
        :isOneJob="true"
        :dialogVisible1="isyxzw"
        @closeDilog1="closeDilog1"
        @saveJobArr="saveJobArr"
      ></choseonezw>
      <choseaddress
        :addressDialogShow="addressDialogShow"
        @submitAddress="submitAddress"
        @closeAddresPop="closeAddresPop"
      ></choseaddress>
      <chosepartjobtime
        :partJobDialogShow="partJobTimeDialogShow"
        @partJobTimeArr="partJobTimeArr"
      ></chosepartjobtime>
    </div>
    <PayJobDia
        :isopen="isOpenPayJob"
        :params="payJobParams"
        @payJobChang="payJobChang"
    ></PayJobDia>
  </div>
</template>

<script>
import {
  getReleaseJobCalss,
  addFullJob,
  testingJobStatus,
  addPartJob, getJobsSkillsList, getJobsWeafList,
} from "@/api/compe/jobsApi.js";
import { fistChatToMsg } from "@/api/commen/chatApi.js";
import choseonezw from "@/components/choseZw.vue";
import choseaddress from "@/components/choseAddressDialog.vue";
import chosepartjobtime from "@/components/comUser/chosePartJobTime.vue";
import registhead from "@/components/comUser/registHead.vue";
import steppro from "@/components/comUser/stepCom.vue";
import PayJobDia from "@/components/jobClass/payJob.vue";
export default {
  components: {
    PayJobDia,
    registhead,
    steppro,
    choseonezw,
    choseaddress,
    chosepartjobtime,
  },
  data() {
    return {
      isOpenPayJob:false,
      payJobParams:{},
      weafList:[],
      jobWeafDialogVisible: false,
      choseWeafArr: [],
      skillsDialogVisible: false,
      skillList: [],
      choseSkillArr: [],
      isNegotiable:false,
      tmpName: "",
      navBarId: 1,
      isyxzw: false,
      expChoseArr: [],
      eduChoseArr: [],
      minWageChoseArr: [],
      maxWageChoseArr: [],
      addressDialogShow: false,
      partJobTimeDialogShow: false,
      slpTimeChoseArr: [
        {
          id: 2,
          name: "双休",
        },
        {
          id: 1,
          name: "单休",
        },
        {
          id: 3,
          name: "排班轮休",
        },
      ],
      jbTimeChoseArr: [
        {
          id: 1,
          name: "不加班",
        },
        {
          id: 2,
          name: "偶尔加班",
        },
        {
          id: 3,
          name: "弹性工作",
        },
      ],
      editJobObj: {
        name: "",
        job1: "",
        job1_son: "",
        exp: "",
        edu: "",
        description: "",
        provinceid: "",
        cityid: "",
        three_cityid: "",
        x: "",
        y: "",
        minsalary: "",
        maxsalary: "",
        address: "",
        release_type: "normal",
        rating_id: "",
        addr_id: "",
        work_time: "",
        overtime_state: "",
        rest_day: "",
        work_shift: "",
        com_alias: "",
        salary_type:1,
        job_wf:'',
        skill:'',
      },
      partJobObj: {
        name: "",
        type: "",
        number: "",
        worktime: "",
        // deadline: this.data.deadline,
        sex: "",
        salary: "",
        salary_type: "",
        billing_cycle: "",
        provinceid: "",
        cityid: "",
        three_cityid: "",
        x: "",
        y: "",
        address: "",
        content: "",
        linkman: "",
        linktel: "",
        addr_id: "",
      },
      partJobTypeName: "",
      startWorkTime: "",
      endWorkTime: "",
      timeShowText: "",
      partSalaryType: ["元/次", "元/天", "元/周", "元/月"],
      partSalarySyc: ["天结", "周结", "月结", "完工结"],
      fullrules: {
        name: [{ required: true, message: "请填写招聘职位", trigger: "blur" }],
        job1_son: [
          { required: true, message: "请选择职位类别", trigger: "change" },
        ],
        exp: [{ required: true, message: "请选择经验要求", trigger: "blur" }],
        edu: [{ required: true, message: "请选择学历要求", trigger: "blur" }],
        skill: [{ required: true, message: "请选择职位技能标签", trigger: "change" }],
        job_wf: [{ required: true, message: "请选择福利待遇", trigger: "change" }],
        address: [
          { required: true, message: "请选择工作地点", trigger: "change" },
        ],
        description: [
          { required: true, message: "请填写职位描述", trigger: "blur" },
        ],
      },
    };
  },

  methods: {
    closeAddresPop(){
      this.addressDialogShow = false;
    },
    payJobChang(){
      this.isOpenPayJob = false;
      this.$router.push("/userhomepage");
    },
    saveJobSkill() {
      this.editJobObj.skill = String(this.choseSkillArr);
      this.skillsDialogVisible = false;
    },
    choseSkill(item) {
      if (this.choseSkillArr.length >= 6) {
        this.$message({
          message: "最多只能选择6个",
          type: "warning",
        });
        return;
      }
      if (this.choseSkillArr.length > 0) {
        if (this.choseSkillArr.indexOf(item) === -1) {
          this.choseSkillArr.push(item);
        } else {
          this.$message({
            message: "不能重复添加",
            type: "warning",
          });
        }
      } else {
        this.choseSkillArr.push(item);
      }
    },

    deleChoseSkill(item, index) {
      this.choseSkillArr.splice(index, 1);
      // this.editJobObj.skill = this.choseSkillArr.join();
    },
    // 福利确认
    saveJobWeaf() {
      this.editJobObj.job_wf = String(this.choseWeafArr);
      this.jobWeafDialogVisible = false;
    },
    // 自定义福利/标签
    addCustomTag(type) {
      this.$prompt("请输入标签", "自定义标签", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
          .then(({ value }) => {
            if (type === 1) {
              this.choseSkillArr.push(value);
            } else {
              this.choseWeafArr.push(value);
            }
          })
          .catch(() => {});
    },
    choseJobWeaf(item) {
      if (this.choseWeafArr.length >= 6) {
        this.$message({
          message: "最多只能选择6个",
          type: "warning",
        });
        return;
      }
      if (this.choseWeafArr.length > 0) {
        // this.sktags.forEach((it) => {
        if (this.choseWeafArr.indexOf(item) === -1) {
          this.choseWeafArr.push(item);
        } else {
          this.$message({
            message: "不能重复添加",
            type: "warning",
          });
          // uni.showToast({
          //   title: "已添加",
          //   icon: "none",
          // });
        }
        // });
      } else {
        this.choseWeafArr.push(item);
      }
    },
    deleJobWeaf(item, index) {
      this.choseWeafArr.splice(index, 1);
    },
    /* 获取职位福利 */
    getJobsWeafList() {
      getJobsWeafList()
          .then((result) => {
            console.log("获取职位福利", result);
            var ls = [];
            result.data.job_wf.forEach((element) => {
              ls.push(element.name);
            });
            this.weafList = ls;
            this.jobWeafDialogVisible = true;
          })
          .catch(() => {});
    },
    /* 获取技能标签 */
    getJobsSkillsList() {
      if (this.editJobObj.job1_son == "") {
        this.$message({
          message: "请先选择职位类别",
          type: "warning",
        });
        return;
      }
      getJobsSkillsList({
        job1: this.editJobObj.job1,
        job1_son: this.editJobObj.job1_son,
      })
          .then((result) => {
            console.log("获取技能标签", result);
            this.skillList = result.data;
            this.skillsDialogVisible = true;
          })
          .catch(() => {});
    },
    changeJobType(e) {
      this.navBarId = e;
    },
    getReleaseJobCalss(name) {
      getReleaseJobCalss({ name })
        .then((result) => {
          console.log("获取经验要求", result);
          if (name == "job_exp") {
            this.expChoseArr = result.data.list.job_exp;
          } else if (name == "job_edu") {
            this.eduChoseArr = result.data.list.job_edu;
          }
        })
        .catch(() => {});
    },
    getMinWageList() {
      this.minWageChoseArr = [];
      for (let i = 1; i < 100; i++) {
        this.minWageChoseArr.push(i * 1000);
        // if (i < 5) {
        //   this.multiArray[1].push(i + 1 + "k");
        // }
      }
    },
    getMaxWageList() {
      this.maxWageChoseArr = [];
      if (this.editJobObj.minsalary != "") {
        for (let i = this.editJobObj.minsalary / 1000; i < 100; i++) {
          this.maxWageChoseArr.push(parseInt(i + 1) * 1000);
        }
      } else {
        for (let i = 1; i < 100; i++) {
          this.maxWageChoseArr.push(i * 1000);
        }
      }
    },

    saveJobArr(e) {
      console.log('zhi wei lei bie:',e);
      if (this.navBarId == 1) {
        this.editJobObj.job1_son = e.id;
        this.editJobObj.job1 = e.keyid;
        this.tmpName = e.name;
      } else {
        this.partJobTypeName = e.name;
        this.partJobObj.type = e.id;
      }
      this.getJobDespMod();
      this.isyxzw = false;
    },

    /* 获取描述模版 */

    getJobDespMod() {
      fistChatToMsg({
        type: 1,
        job_id: this.editJobObj.job1_son,
      })
        .then((result) => {
          console.log("获取模版", result);
          this.editJobObj.description = result.data.content;
        })
        .catch(() => {});
    },

    changeJobTime(e) {
      console.log(e);
      this.editJobObj.work_shift = e;
    },
    minWageChose(e) {
      console.log(e);
    },
    maxWageChose() {},
    expChose(e) {
      console.log(e);
    },
    eduChose(e) {
      console.log(e);
    },
    slpTimeChose(e) {
      console.log(e);
    },
    jbTimeChose(e) {
      console.log(e);
    },
    closeDilog1() {
      this.isyxzw = false;
    },

    submitAddress(e) {
      console.log("选地址", e);
      if (this.navBarId == 1) {
        this.editJobObj.addr_id = e.id;
        this.editJobObj.x = e.x;
        this.editJobObj.y = e.y;
        this.editJobObj.provinceid = e.provinceid;
        this.editJobObj.cityid = e.cityid;
        this.editJobObj.three_cityid = e.three_cityid;
        this.editJobObj.address = e.address1 + e.address2;
      } else {
        this.partJobObj.addr_id = e.id;
        this.partJobObj.x = e.x;
        this.partJobObj.y = e.y;
        this.partJobObj.provinceid = e.provinceid;
        this.partJobObj.cityid = e.cityid;
        this.partJobObj.three_cityid = e.three_cityid;
        this.partJobObj.address = e.address1 + e.address2;
      }
      this.addressDialogShow = false;
    },
    payJobDiaFun(params,jobId,jobType){
      if (!params) {
        this.$router.go(-1);
        return;
      }
      params.job_id = jobId;
      params.job_type = jobType;
      this.payJobParams = params;
      this.isOpenPayJob = true;
    },
    /* 新建全职工作 */
    addFullJob() {
      console.log("新建工作", this.editJobObj);
      if (this.navBarId == 1) {
        if (!this.isNegotiable){
          if (!this.editJobObj.minsalary || !this.editJobObj.maxsalary){
            return this.$message.error('请选择综合薪资或面议！');
          }
        }
        this.$refs["fullJob-ref"].validate((valid) => {
          if (valid) {
            testingJobStatus({
              id: "",
              job_id: this.editJobObj.job1_son,
              provinceid: this.editJobObj.provinceid,
              cityid: this.editJobObj.cityid,
              three_cityid: this.editJobObj.three_cityid,
              maxsalary: this.editJobObj.minsalary,
              minsalary: this.editJobObj.maxsalary,
              addr_id: this.editJobObj.addr_id,
            })
              .then((result) => {
                console.log("判断职位", result);
                // if (result.msg == "薪资低") {
                //   this.$confirm(result.msg, "提示", {
                //     confirmButtonText: "提升薪资",
                //     cancelButtonText: "任性发布",
                //     type: "warning",
                //   })
                //       .then(() => {})
                //       .catch(() => {
                //         this.saveJob();
                //       });
                // } else
                if (result.msg == "职位重复") {
                  this.$confirm(result.msg, "提示", {
                    confirmButtonText: "前去修改",
                    cancelButtonText: "重新发布",
                    type: "warning",
                  })
                    .then(() => {})
                    .catch(() => {
                      this.saveJob();
                    });
                } else {
                  this.saveJob();
                }
              })
              .catch(() => {});
          } else {
            return false;
          }
        });
      } else {
        this.savePartJob();
      }
    },
    saveJob() {
      this.editJobObj.salary_type = this.isNegotiable ? 1 : 2;
      if (this.startWorkTime && this.endWorkTime) {
        if (this.startWorkTime.charAt(0) === '0') {
          this.startWorkTime = this.startWorkTime.substring(1);
        }
        if (this.endWorkTime.charAt(0) === '0') {
          this.endWorkTime = this.endWorkTime.substring(1);
        }
        this.editJobObj.work_time = this.startWorkTime + "-" + this.endWorkTime;
      } else {
        this.editJobObj.work_time = "";
      }
      addFullJob(this.editJobObj)
        .then((result) => {
          console.log("新建工作", this.editJobObj, result);
          if (result.code == 200) {
            this.$message({
              message: "发布成功！",
              type: "success",
              center: true,
            });
            // this.payJobDiaFun(result.data.isPay,result.data.job_id,this.editJobObj.job1_son);
            this.$router.push("/userhomepage");
          }
        })
        .catch(() => {});
    },
    savePartJob() {
      addPartJob(this.partJobObj)
        .then((result) => {
          console.log("新建兼职工作", result);
        })
        .catch(() => {});
    },
    partJobTimeArr(e) {
      console.log(String(e));
      if (e.length > 0) {
        this.timeShowText = "已选择";
        this.partJobObj.worktime = String(e);
      }

      this.partJobTimeDialogShow = false;
    },
    toIndexPage() {
      this.$router.push("/home");
    },
    goBackPage() {
      this.$router.back();
    },
  },
};
</script>

<style>
.business-releasejob-dialog {
  background-color: #fff;
  width: 975px;
  padding: 25px 23px;
  border-radius: 20px;
}
.business-releasejob-dialog .add-job-view {
  width: 860px;
  margin-left: 49px;
}
.business-releasejob-dialog .top-nav-bar-view {
  width: 852px;
  height: 60px;
  background-color: #fff;
  margin-top: 40px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.business-releasejob-dialog .top-nav-bar-view .nav-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 852px;
  height: 60px;
  background-color: #f5f6fa;
  border-radius: 40px;
}
.business-releasejob-dialog .top-nav-bar-view .nav-bar .bar-item {
  width: 416px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;

  font-weight: 400;
  color: #191919;
  border-radius: 26px;
}
.business-releasejob-dialog .top-nav-bar-view .nav-bar .nav-sel {
  background-color: #fff;
  color: #fe6002;
}
.business-releasejob-dialog .job-inf-view {
  background-color: #fff;
}

.business-releasejob-dialog .edi-one-item .el-input__inner {
  height: 45px;
  font-size: 18px;
}
.business-releasejob-dialog .edi-one-item .el-textarea__inner {
  font-size: 18px;
}

.business-releasejob-dialog .edi-lin-item .el-form-item {
  /* width: 400px; */
  /* width: 852px; */
}
.business-releasejob-dialog .edi-one-item .items .title {
  font-size: 22px;

  font-weight: 400;
  color: #191919;
  line-height: 38px;
}

.business-releasejob-dialog .edi-lin-item .el-form-item .items {
  width: 400px;
}

.business-releasejob-dialog
  .edi-lin-item
  .el-form-item
  .items
  .el-select
  .el-input__inner {
  width: 400px;
  height: 45px;
}
.business-releasejob-dialog .edi-lin-item .el-form-item .items .title {
  font-size: 18px;

  font-weight: 400;
  color: #191919;
  line-height: 38px;
}

.business-releasejob-dialog .edi-lin-item .el-date-editor--time-select {
  width: 400px;
}
.business-releasejob-dialog
  .edi-lin-item
  .el-date-editor--time-select
  .el-input__inner {
  width: 400px;
  height: 45px;
  font-size: 20px;

  font-weight: 400;
  color: #b1b1b1;
  line-height: 28px;
}

.business-releasejob-dialog .edi-lin-item .el-input .el-input__inner {
  width: 400px;
  height: 45px;
  font-size: 18px;
}

.business-releasejob-dialog .choseview {
  position: relative;
}

.business-releasejob-dialog .choseview .tt {
  font-size: 18px;

  font-weight: 400;
  color: #191919;
}

.business-releasejob-dialog .choseview .choseview-input-view {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 820px;
}

.business-releasejob-dialog .choseview .choseview-input-view .ch-item-s {
  display: block;
  width: 247px;
  height: 45px;
  border-radius: 5px;
  border: 1px solid #e4e7ed;
  text-align: center;
  background-color: #fff;
  color: #b1b1b1;
  line-height: 45px;
  font-size: 20px;
}

.business-releasejob-dialog .choseview .choseview-input-view .sl-sex {
  background-color: #fff5f0;
  color: #fe6002;
  border: 1px solid #fe6002;
}
.business-releasejob-dialog .bott-save-btn .el-button--default {
  width: 196px;
  height: 50px;
  border: none;
  background-color: #f5f6fa;
}

.business-releasejob-dialog .bott-save-btn .el-button--primary {
  width: 368px;
  height: 50px;
  margin-left: 20px;
}
.business-releasejob-dialog .job-inf-view label {
  font-size: 20px;
  color: #191919;
}
.business-releasejob-dialog .releasejob-edi-sl-view .regist-job-salary .items .el-input__inner {
  width: 300px !important;
}
.business-releasejob-dialog .releasejob-edi-sl-view .edi-lin-item .items .el-input.el-input--suffix {
  width: auto;
}
.business-releasejob-dialog .releasejob-edi-sl-view .regist-job-salary .el-checkbox .el-checkbox__label{
  font-size: 18px;
}
.business-releasejob-dialog .releasejob-edi-sl-view .regist-job-salary .items .el-select {
  width: 300px;
}


.business-releasejob-dialog .jn-dialog {
  margin: 20px 30px;
  padding-bottom: 30px;
}

.business-releasejob-dialog .jn-dialog .tt2 {
  font-size: 20px;
  font-weight: 600;
  color: #191919;
  line-height: 28px;
  margin-top: 30px;
}
.business-releasejob-dialog .jn-dialog .sl-bq-btn {
  margin-top: 24px;
  /* display: flex;
  align-items: center; */
}

.business-releasejob-dialog .jn-dialog .sl-bq-btn .addbq-bt {
  width: 86px;
  height: 36px;
  border: 1px solid #fe6002;
  font-size: 18px;

  font-weight: 400;
  color: #fe6002;
  line-height: 36px;
  border-radius: 2px;
  text-align: center;
  display: inline-block;
  margin-bottom: 10px;
}
.business-releasejob-dialog .jn-dialog .sl-bq-btn .sel-jn-tag {
  display: flex;
  align-items: center;
  display: inline-block;
  font-size: 18px;

  font-weight: 400;
  color: #fffdfc;
  line-height: 24px;
  background-color: #ff8136;
  padding: 7px 8px;
  border-radius: 2px;
  margin-right: 16px;
  margin-bottom: 10px;
}
.business-releasejob-dialog .jn-dialog .sl-bq-btn .sel-jn-tag span {
  margin-right: 10px;
}

.business-releasejob-dialog .jn-dialog .hot-bq-view .hotbq-item {
  display: inline-block;
  background-color: #f5f6fa;
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 16px;

  font-weight: 400;
  color: #757575;
  line-height: 19px;
  margin-top: 20px;
  margin-right: 20px;
}

.business-releasejob-dialog .jn-dialog .hot-bq-view .add-other {
  width: 120px;
  background-color: #f5f6fa;
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 400;
  color: #757575;
  line-height: 19px;
  margin-top: 20px;
  margin-right: 20px;
}

.business-releasejob-dialog .jn-dialog .botm-btn-view {
  margin-top: 30px;
  width: 100%;
  text-align: right;
}

.business-releasejob-dialog .jn-dialog .botm-btn-view .el-button {
  width: 300px;
}
.business-releasejob-dialog .el-dialog__wrapper .el-dialog {
  border-radius: 15px;
}
</style>